import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { MakeAxiosRequest } from "../../utils/handler";

const PhoneNumberField = (props) => {
  const {
    control,
    setValue,
    formState: { errors },
    trigger, // To trigger revalidation
    getValues,
  } = useFormContext();
  const inputRef = React.createRef();
  const [countrycodes, setCountryCodes] = useState([""]);

  useEffect(() => {
    getCountryCodes();
  }, []);

  const getCountryCodes = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/getcountries");
      // console.log(response)
      if (response.status == 1) {
        const countryCodes = response.data.countryCodeList?.map((item) => ({
          // Transform each item as needed
          id: item.countryid,
          name: item.countrycode,
        }));
        setCountryCodes(countryCodes);
        getValues(props.countryCodeName) || setValue(props.countryCodeName,countryCodes[0].name); // Update the form value
        // alert(getValues(props.countryCodeName));
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleCountryCodeChange = (e) => {
    console.log(e);
    console.log(e.target.value);
    setValue(props.countryCodeName, e.target.value);
    trigger(props.name); // Revalidate the phone number field
  }

  return (
    <div className={props.className}>
      {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
          {/* {(props.rules && props.rules.required) && "*"} */}
        </label>
      )}
      <div className={`${props.icon && "input-group"}`}>
        <span className={`${props.icon && "input-group-text"}`}>
          {props.icon}
        </span>
        <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field }) => (
            <div className="phone-form">
              <div className="phone-code-dropdown">
                <select
                  className="form-select"
                  onChange={handleCountryCodeChange} // Update field value
                  value={getValues(props.countryCodeName)}
                >
                  {countrycodes.map((code,i) => (
                    <option key={i} value={code.name}>
                      {code.name}
                    </option>
                  ))}
                </select>
              </div>
              <InputMask
                {...field}
                mask={props.mask}
                placeholder={props.placeholder}
                maskChar="" // Prevents showing underscores as placeholders
                ref={inputRef} // Pass the ref to InputMask
                disabled={props?.isdisabled}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type={props.inputType || "text"}
                    //  className={`form-control ${errors[props.name] ? 'is-invalid' : ''}`}
                    className="form-control"
                  />
                )}
              </InputMask>
            </div>
          )}
        />
      </div>
      {errors[props.name] && (
        <span className="error">{errors[props.name].message}</span>
      )}
    </div>
  );
};

export default PhoneNumberField;
