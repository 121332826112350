import React, { useState, useEffect } from "react";
import ModalBox from "../../Components/modals/ModalBox";
import { FormProvider, useForm } from "react-hook-form";
import { getArrayFilered, getselectedIds, MakeAxiosRequest } from "../../utils/handler";
import { SwalError, SwalSuccess } from "../../Components/SwalFns";
import FormFields from "../../Components/inputs/FormFields";
import Loader from "../../Components/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

function WalletTopup({ showModal, closeModal, listingFn, proxyKey }) {

  const [isLoading, setIsLoading] = useState(true);
  const [amountlist, setAmountList] = useState("");
  const [data, setData] = useState("");
  const [walletdata, setWalletData] = useState("");
  const [rechargeAmount, setRechargeAmount] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getTopupInfo();
  }, []);

  const getTopupInfo = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/wallettopupinfo", {
        user_proxy_key: proxyKey,
      });
      // console.log(response)
      if (response.status == 1) {
        setData(response.data);
        setWalletData(response.data.wallet_banner);
        const amount = response.data.amounts?.map((item) => ({
          // Transform each item as needed
          id: item.value,
          name: item.amount,
        }));
        const defaultMethod = getArrayFilered(response.data.paymentmethoddetails?.paymentmethods, "isselected", "1");
        // console.log(defaultMethod);
        // console.log(defaultMethod[0].type);
        defaultMethod && setValue('wallet_purchase_type',defaultMethod[0].type);
        setAmountList(amount);
        setIsLoading(false);
      } else {
        closeModal();
        SwalError({ msg: response.message });
        // console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const methods = useForm();
  const { handleSubmit, reset, setValue, watch, trigger, getValues } = methods;
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(
    getValues("purchaseamount")
  );
  const walletTopupSubmit = async (data) => {
    setSubmitDisabled(true);
    try {
      data.wallet_permission_enabled = data.wallet_permission_enabled==1 ? '1' : '0';
      const response = await MakeAxiosRequest(
        "post",
        "/purchaseamounttransactionach",
        { ...data, transactionkey: "", user_proxy_key: proxyKey }
      );
      // console.log(response);
      if (response.status == 1) {
        const originalPath = location.pathname;
// console.log(originalPath);
        navigate("/wallet/billingpreview", {
          state: { responseData: response.data, proxyData : {wallet_purchase_type: data.wallet_purchase_type, user_proxy_key: proxyKey},originalPath },
        });
      } else {
        closeModal();
        // setError('phone_number', { type: 'custom', message: response.message });
        SwalError({ msg: response.message });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };
  const setAmount = (data) => {
    setValue("purchaseamount", data);
    setSelectedAmount(data);
  };
  const amount = watch("purchaseamount");
  useEffect(() => {
    setSelectedAmount(amount);
  }, [amount]);
  const fields = [
    {
      name: "purchaseamount",
      label: "Recharge",
      className: "col-md-12 mb-3",
      icon: "$",
      rules: {
        required: "Please enter recharge amount",
      },
      mask: "99999999999",
      type: "numberfield",
      value: rechargeAmount,
      isShow: true,
      placeholder: "0.00",
    },
  ];

  return (
    <ModalBox
      isShow={showModal}
      handleClose={closeModal}
      modalSize="md"
      hasData={true}
    >
      <>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(walletTopupSubmit)}>
              <div className="text-center mb-4">
                <h3 className="modal-title" id="exampleModalLabel">
                  Wallet Recharge
                </h3>
              </div>
              <div className="form-group mt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="web-card wallet-card modal-wallet-card mb-3">
                      <p className="mb-1">{walletdata.title}</p>
                      <h3 className="mb-0">{walletdata.balance_amount}</h3>
                    </div>
                    <FormFields fields={fields} />
                    <div className="topup-amt py-3">
                      {amountlist?.map((sub, i) => (
                        <a
                          href="javascript:void(0)"
                          className={`btn btn-outline-sm me-2 ${
                            selectedAmount === sub.id ? "active" : ""
                          }`}
                          onClick={() => setAmount(sub.id)}
                        >
                          {sub.name}
                        </a>
                      ))}
                    </div>
                    {data.show_payment_methods === 1 && (
                      <div className="mt-3">
                        <h4>{data.paymentmethoddetails?.title}</h4>
                        <p>{data.paymentmethoddetails?.text}</p>

                        {data.paymentmethoddetails?.paymentmethods?.map(
                          (option, index) => (
                            <div key={index} className="mb-3">
                              <div className="form-check form-checked">
                                <input
                                  name="wallet_purchase_type"
                                  type="radio"
                                  value={option.type}
                                  className="form-check-input"
                                  id={
                                    `wallet_purchase_type-radio-` + option.type
                                  }
                                  onClick={() =>
                                    setValue(
                                      "wallet_purchase_type",
                                      option.type
                                    )
                                  }
                                  defaultChecked={option.isselected == 1}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  htmlFor={
                                    `wallet_purchase_type-radio-` + option.type
                                  }
                                  onClick={() =>
                                    setValue(
                                      "wallet_purchase_type",
                                      option.type
                                    )
                                  }
                                >
                                  {option.label}
                                </label>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill w-100 p-2"
                  disabled={isSubmitDisabled}
                >
                  Continue
                </button>
              </div>
            </form>
          </FormProvider>
        )}
      </>
    </ModalBox>
  );
}

export default WalletTopup;
