import React from "react";
import { Link, useNavigate } from "react-router-dom";
import dummyProfile from "../../Assets/Images/default-profile.png";
import loaderFile from "../../Assets/Images/loader.gif";
import noRecords from "../../Assets/Images/no-records.png";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { formatTextWithLineBreaks, getDecimalVal } from "../../utils/handler";

const areConditionsMet = (conditions, row) => {
  return conditions.every(
    (condition) => row[condition.key] === condition.value
  );
};

function TableContent({ listingData, columns, loadingStatus, type }) {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.auth.user);

  return (
    <>
      <div className="table-responsive">
        {listingData?.length === 0 && !loadingStatus ? (
          <div className="mt-4 text-center">
            <img
              src={noRecords}
              style={{ width: "150px" }}
              alt="No records found"
            />
            <div className="mt-3">
              There are no {type || "data"} to display.
            </div>
          </div>
        ) : (
          <table className="table table-hover table-dark table-list w-100">
            <thead className="">
              <tr>
                {columns?.map((column, index) => {
                  return (
                    column.isShow && (
                      <th scope="col" style={{ width: "auto" }} key={index}>
                        {column.header}
                      </th>
                    )
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {loadingStatus ? (
                <tr>
                  <td colSpan={7}>
                    <div>
                      <center>
                        <img
                          src={loaderFile}
                          style={{ width: "125px" }}
                          alt="Loading..."
                        />
                      </center>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {listingData?.length !== 0 && (
                    <>
                      {listingData?.map((row, i) => {
                        return (
                          <tr key={i}>
                            {columns?.map((column, index) => {
                              switch (column.type) {
                                case "name":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                      >
                                        <div className="">
                                          {column.data.key ? (
                                            <Link
                                              to={`/${column.data.path}/${
                                                row[column.data.key]
                                              }`}
                                              className="profile-sec d-flex align-items-center"
                                            >
                                              <img
                                                src={
                                                  row[column.data.image] ||
                                                  dummyProfile
                                                }
                                                alt=""
                                                className="pfl-img"
                                              />
                                              <p>
                                                {row[column.data.text]}{" "}
                                                {column.data.text_2 &&
                                                  row[column.data.text_2]}
                                              </p>
                                              {column.data.sub_text &&
                                                row[column.data.sub_text] && (
                                                  <span>
                                                    {row[column.data.sub_text]}
                                                  </span>
                                                )}
                                            </Link>
                                          ) : (
                                            <a
                                              href="javascript:void(0)"
                                              className="profile-sec d-flex align-items-center"
                                              onClick={()=>column.data.clickFn && column.data.clickFn(row[column.data.fnKey])}
                                            >
                                              <img
                                                src={
                                                  row[column.data.image] ||
                                                  dummyProfile
                                                }
                                                alt=""
                                                className="pfl-list-img"
                                              />
                                              <p className="mb-0">
                                                {row[column.data.text]}{" "}
                                                {column.data.text_2 &&
                                                  row[column.data.text_2]}
                                              </p>
                                              {column.data.sub_text &&
                                                row[column.data.sub_text] && (
                                                  <span>
                                                    {row[column.data.sub_text]}
                                                  </span>
                                                )}
                                            </a>
                                          )}
                                        </div>
                                      </td>
                                    )
                                  );

                                case "textFunction":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                      >
                                        <div className="">
                                          <a
                                            href="javascript:void(0)"
                                            className="profile-sec d-flex align-items-center"
                                            onClick={()=>column.data.clickFn && column.data.clickFn(row[column.data.fnKey])}
                                          >
                                            <p className="primary mb-0" 
                                              style={{
                                                color:
                                                  row[column.data.textColor],
                                              }}>
                                              {row[column.data.text]}
                                            </p>
                                          </a>
                                        </div>
                                      </td>
                                    )
                                  );
                                case "file":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                      >
                                        <div>
                                          <a
                                            href="javascript:void(0)"
                                            className="profile-sec"
                                          >
                                            {row[column.data.type] == "file" ? (
                                              <>
                                                {row[column.data.icon] != "" ? (
                                                  <i
                                                    className={
                                                      row[column.data.icon]
                                                    }
                                                  ></i>
                                                ) : (
                                                  <img
                                                    src={
                                                      row[column.data.path] ||
                                                      dummyProfile
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <i className="fa-solid fa-folder me-2"></i>
                                            )}
                                            <p className="file-name">
                                              {row[column.data.text]}
                                            </p>
                                          </a>
                                        </div>
                                      </td>
                                    )
                                  );

                                case "link":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        {column.data.key ? (
                                          <Link
                                            to={`/${column.data.path}/${
                                              row[column.data.key]
                                            }`}
                                            className="profile-sec d-flex align-items-center"
                                          >
                                            <p
                                              style={{
                                                color:
                                                  row[column.data.textColor],
                                              }}
                                              className={column?.className}
                                            >
                                              {row[column.data.text] || "-"}
                                            </p>
                                          </Link>
                                        ) : (
                                          <p
                                            style={{
                                              color: row[column.data.textColor],
                                            }}
                                            className={column?.className}
                                          >
                                            {row[column.data.text] || "-"}
                                          </p>
                                        )}
                                      </td>
                                    )
                                  );

                                case "text":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p
                                          style={{
                                            color: row[column.data.textColor],
                                          }}
                                          className={`${column?.className} ${column.function && "pointer"}`}
                                          onClick={() =>
                                            column.function(column.fnKey ? row[column.fnKey] : row)
                                          }
                                        >
                                          {column.data.textPrefix}{" "}
                                          {row[column.data.text] || "-"}
                                        </p>
                                        {row[column.data.showSupport] == 1 &&
                                          row[column.data.supportKey] && (
                                            <a
                                              className="btn chat-btn"
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                navigate(
                                                  "/servicerequest/" +
                                                    row[
                                                      column.data.supportKey
                                                    ] +
                                                    "/support"
                                                )
                                              }
                                            >
                                              <i className="fa-solid fa-comments"></i>{" "}
                                              Support
                                            </a>
                                          )}
                                      </td>
                                    )
                                  );

                                case "formatText": {
                                  const formattedText =
                                    formatTextWithLineBreaks(
                                      row[column.data.text]
                                    );

                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: formattedText,
                                          }}
                                        />
                                      </td>
                                    )
                                  );
                                }
                                case "amount":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p>
                                          {column.data.textPrefix}{" "}
                                          {row[column.data.text].replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          ) || "-"}
                                        </p>
                                      </td>
                                    )
                                  );

                                case "size":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p>
                                          {row[column.data.size]
                                            ? getDecimalVal(
                                                row[column.data.size]
                                              ) + " MB"
                                            : "0 MB"}
                                        </p>
                                      </td>
                                    )
                                  );

                                case "date":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p>
                                          {row[column.data.text]
                                            ? format(
                                                new Date(row[column.data.text]),
                                                "MM/dd/y"
                                              )
                                            : "--"}
                                        </p>
                                      </td>
                                    )
                                  );

                                case "array":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p>
                                          {column.data.array[
                                            row[column.data.key]
                                          ] || "-"}
                                        </p>
                                      </td>
                                    )
                                  );

                                case "plans":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        {column.data.array ? (
                                          // If row[column.data.array] exists and is not empty
                                          column.data.array.map(
                                            (each, index) => (
                                              <span
                                                key={index}
                                                className="status-tag status-tag-active"
                                              >
                                                {each[column.data.text]}
                                              </span>
                                            )
                                          )
                                        ) : row[column.data.text] ? (
                                          <span className="status-tag status-tag-active">
                                            {column.data.textPrefix}{" "}
                                            {row[column.data.text]}
                                          </span>
                                        ) : (
                                          <span className="status-tag status-tag-active">
                                            {column.data.textPrefix}
                                          </span>
                                        )}
                                      </td>
                                    )
                                  );

                                case "icons":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <div className="d-flex align-items-center">
                                          {column.data.array
                                            ? // If row[column.data.array] exists and is not empty
                                              row[column.data.array]?.map(
                                                (each, index) => (
                                                  <span
                                                    key={index}
                                                    className="services-logo me-2"
                                                  >
                                                    <img
                                                      src={
                                                        each[column.data.text]
                                                      }
                                                    />
                                                  </span>
                                                )
                                              )
                                            : row[column.data.text] && (
                                                <span className="status-tag status-tag-active">
                                                  {row[column.data.text]}
                                                </span>
                                              )}
                                        </div>
                                      </td>
                                    )
                                  );

                                case "headshots":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <div className="headshort-wrapper d-flex justify-content align-items-center">
                                          {row[column.data.key]?.[
                                            column.data.headshots
                                          ]?.length !== 0 &&
                                            row[column.data.key]?.[
                                              column.data.headshots
                                            ]
                                              ?.slice(0, 3)
                                              .map((each, index) => (
                                                <div
                                                  key={index}
                                                  className="members-img members-headshot"
                                                >
                                                  <img
                                                    src={
                                                      each[column.data.image] ||
                                                      dummyProfile
                                                    }
                                                    alt={each[column.data.name]}
                                                  />
                                                </div>
                                              ))}
                                          {row[column.data.key]?.[
                                            column.data.count
                                          ] > 3 && (
                                            <div
                                              key={index}
                                              className="members-img members-headshot"
                                            >
                                              +{" "}
                                              {row[column.data.key]?.[
                                                column.data.count
                                              ] - 3}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    )
                                  );

                                case "status":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        {(() => {
                                          switch (row[column.data.status]) {
                                            case 1:
                                              return (
                                                <span className="status-tag status-tag-active">
                                                  Active
                                                </span>
                                              );
                                            default:
                                              return (
                                                <span className="status-tag status-tag-inactive">
                                                  Inactive
                                                </span>
                                              );
                                          }
                                        })()}
                                      </td>
                                    )
                                  );

                                case "createdby":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p>
                                          {column.data.name &&
                                            (row[column.data.name] || "-")}
                                        </p>
                                        {row[column.data.email] && (
                                          <span>{row[column.data.email]}</span>
                                        )}
                                        {row[column.data.date] && (
                                          <span>
                                            {format(
                                              new Date(row[column.data.date]),
                                              "dd MMM, y"
                                            )}
                                          </span>
                                        )}
                                      </td>
                                    )
                                  );

                                case "updatedby":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric"
                                      >
                                        <p>{row.updatedby}</p>
                                        <span>{row.updatedbyphonenumber}</span>
                                      </td>
                                    )
                                  );

                                case "actionList":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="numeric d-none d-lg-block"
                                      >
                                        <div className="d-flex justify-content-end">
                                          <div className="btn-group option-btn">
                                            <a
                                              href="javascript:void(0)"
                                              className="btn dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              data-bs-display="static"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                              {column.buttons?.map((btn, i) => {
                                                switch (btn.type) {
                                                  case "view":
                                                    return (
                                                      btn.isShow && (
                                                        <li>
                                                          <a
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                              btn.function(
                                                                row[btn.key]
                                                              )
                                                            }
                                                          >
                                                            <i className="fa-solid fa-eye me-2"></i>
                                                            View
                                                          </a>
                                                        </li>
                                                      )
                                                    );
                                                  case "edit":
                                                    return (
                                                      btn.isShow && (
                                                        <li>
                                                          <a className="dropdown-item">
                                                            <i className="fa-solid fa-pen me-2"></i>
                                                            Edit
                                                          </a>
                                                        </li>
                                                      )
                                                    );
                                                  case "approve":
                                                    return (
                                                      btn.isShow && (
                                                        <li>
                                                          <a className="dropdown-item success">
                                                            <i className="fa-regular fa-square-check me-2"></i>
                                                            Approve
                                                          </a>
                                                        </li>
                                                      )
                                                    );
                                                  case "reject":
                                                    return (
                                                      btn.isShow && (
                                                        <li>
                                                          <a className="dropdown-item reject">
                                                            <i className="fa-solid fa-trash-can me-2"></i>
                                                            Reject
                                                          </a>
                                                        </li>
                                                      )
                                                    );
                                                  case "mark":
                                                    return (
                                                      btn.isShow && (
                                                        <li>
                                                          {row[btn.status] ==
                                                          "0" ? (
                                                            <a
                                                              className="dropdown-item success"
                                                              onClick={() =>
                                                                btn.function(
                                                                  row[btn.key],
                                                                  row[
                                                                    btn.status
                                                                  ]
                                                                )
                                                              }
                                                            >
                                                              <i className="fa-regular fa-square-check me-2"></i>
                                                              Mark
                                                            </a>
                                                          ) : (
                                                            <a
                                                              className="dropdown-item reject"
                                                              onClick={() =>
                                                                btn.function(
                                                                  row[btn.key],
                                                                  row[
                                                                    btn.status
                                                                  ]
                                                                )
                                                              }
                                                            >
                                                              <i className="fa-solid fa-trash-can me-2"></i>
                                                              Unmark
                                                            </a>
                                                          )}
                                                        </li>
                                                      )
                                                    );
                                                  default:
                                                    return null;
                                                }
                                              })}
                                            </ul>
                                          </div>
                                        </div>
                                      </td>
                                    )
                                  );

                                case "actionBtns":
                                  return (
                                    column.isShow && (
                                      <td
                                        data-title={column.header}
                                        key={index}
                                        className="text-end"
                                      >
                                        <div className="d-flex align-items-center justify-content-end">
                                          {column.buttons?.map((btn, i) => {
                                            switch (btn.type) {
                                              case "view":
                                                return (
                                                  btn.isShow &&
                                                  (!btn.condition ||
                                                    areConditionsMet(
                                                      btn.condition,
                                                      row
                                                    )) && (
                                                    <a
                                                      key={i}
                                                      className="btn opt-btn"
                                                      title="View"
                                                      onClick={() =>
                                                        btn.function(
                                                          btn.key ? row[btn.key] : row
                                                        )
                                                      }
                                                    >
                                                      <i className="fa-solid fa-eye"></i>
                                                    </a>
                                                  )
                                                );
                                              case "edit":
                                                return (
                                                  btn.isShow &&
                                                  (!btn.condition ||
                                                    areConditionsMet(
                                                      btn.condition,
                                                      row
                                                    )) && (
                                                    <a
                                                      key={i}
                                                      className="btn opt-btn"
                                                      title="Edit"
                                                      onClick={() =>
                                                        btn.function(
                                                          row[btn.key]
                                                        )
                                                      }
                                                    >
                                                      <i className="fa-solid fa-pen"></i>
                                                    </a>
                                                  )
                                                );

                                              case "download":
                                                return (
                                                  btn.isShow && (
                                                    <a
                                                      key={i}
                                                      className="btn opt-btn"
                                                      title="Download"
                                                      onClick={() =>
                                                        btn.function(
                                                          btn.key ? row[btn.key] : row
                                                        )
                                                      }
                                                    >
                                                      <i className="fa-solid fa-download"></i>
                                                    </a>
                                                  )
                                                );
                                              case "delete":
                                                return (
                                                  btn.isShow &&
                                                  (!btn.condition ||
                                                    areConditionsMet(
                                                      btn.condition,
                                                      row
                                                    )) && (
                                                    <>
                                                      <a
                                                        key={i}
                                                        className="btn opt-btn"
                                                        title="Delete"
                                                        onClick={() =>
                                                          btn.function(
                                                            row[btn.key],
                                                            row[btn.status]
                                                          )
                                                        }
                                                      >
                                                        <i className="fa-solid fa-trash-can"></i>
                                                      </a>
                                                    </>
                                                  )
                                                );
                                              default:
                                                return null;
                                            }
                                          })}
                                        </div>
                                      </td>
                                    )
                                  );

                                default:
                                  return null;
                              }
                            })}
                          </tr>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default TableContent;
