import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import Login from "./Pages/Login/Login";
import ProtectedRoute from "./utils/ProtectedRoute";
import Layout from "./Components/layout/Layout";
import Dashboard from "./Pages/Dashboard/Dashboard"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const Category = React.lazy(() => import('./Pages/Category/Category'));
const Relationship = React.lazy(() => import('./Pages/Relationship/Relationship'));
const Notifications = React.lazy(() => import('./Pages/Notifications/Notifications'));
const ServiceRequest = React.lazy(() => import('./Pages/ServiceRequest/ServiceRequest'));
const ServiceRquestDetails = React.lazy(() => import('./Pages/ServiceRequest/ServiceRquestDetails'));
const ServiceRequestChat = React.lazy(() => import('./Pages/ServiceRequest/ServiceRequestChat'));
const MembershipBillingInfo = React.lazy(() => import('./Pages/Membership/MembershipBillingInfo'));
const MyCardList = React.lazy(() => import('./Pages/MyCard/MyCardList'));
const Wallet = React.lazy(() => import('./Pages/Wallet/Wallet'));
const Payment = React.lazy(() => import('./Pages/Payment/Payment'));
const Support = React.lazy(() => import('./Pages/ServiceRequest/Support'));
const Membership = React.lazy(() => import('./Pages/Membership/Membership'));
const GetStarted = React.lazy(() => import('./Pages/Trial/GetStarted'));
const TrialInfo = React.lazy(() => import('./Pages/Trial/TrialInfo'));
const Receipts = React.lazy(() => import('./Pages/Receipts/Receipts'));


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

    <Router>
      <ProtectedRoute>
      <Layout>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/:type/billingpreview" element={<Suspense fallback={<div>Loading...</div>}><Payment/></Suspense>}/>
        <Route path="/categories" element={<Suspense fallback={<div>Loading...</div>}><Category/></Suspense>}/>
        <Route path="/relationship" element={<Suspense fallback={<div>Loading...</div>}><Relationship/></Suspense>} />
        <Route path="/notifications" element={<Suspense fallback={<div>Loading...</div>}><Notifications/></Suspense>}/>
        <Route path="/servicerequests" element={<Suspense fallback={<div>Loading...</div>}><ServiceRequest/></Suspense>}/>
        <Route path="/servicerequests/:proxyKey" element={<Suspense fallback={<div>Loading...</div>}><ServiceRequest/></Suspense>}/>
        <Route path="/servicerequest/:key" element={<Suspense fallback={<div>Loading...</div>}><ServiceRquestDetails/></Suspense>}/>
        <Route path="/servicerequest/:key/support" element={<Suspense fallback={<div>Loading...</div>}><Support/></Suspense>}/>
        <Route path="/servicerequest/:type/:service_uuid" element={<Suspense fallback={<div>Loading...</div>}><ServiceRequestChat/></Suspense>}/>
        <Route path="/mycards" element={<Suspense fallback={<div>Loading...</div>}><MyCardList/></Suspense>}/>
        <Route path="/wallet" element={<Suspense fallback={<div>Loading...</div>}><Wallet/></Suspense>}/>
        <Route path="/wallet/:proxyKey" element={<Suspense fallback={<div>Loading...</div>}><Wallet/></Suspense>}/>
        <Route path="/membership/billinginfo" element={<Suspense fallback={<div>Loading...</div>}><MembershipBillingInfo/></Suspense>}/>
        <Route path="/membership" element={<Suspense fallback={<div>Loading...</div>}><Membership/></Suspense>}/>
        <Route path="/getstarted" element={<Suspense fallback={<div>Loading...</div>}><GetStarted/></Suspense>}/>
        <Route path="/trialinfo" element={<Suspense fallback={<div>Loading...</div>}><TrialInfo/></Suspense>}/>
        <Route path="/myreceipts" element={<Suspense fallback={<div>Loading...</div>}><Receipts/></Suspense>}/>
        <Route path="/*" element={<Navigate to="/dashboard" />}/>
      </Routes>
      </Layout>
      </ProtectedRoute>
    </Router>
    </LocalizationProvider>

  );
}

export default App;
